import React from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"
import { globalHistory } from "@reach/router"

import { Colors } from "../../utils/constants"
import LinkButton from "../LinkButton/LinkButton"

const Menu = ({ items, title, ...props }) => {
  const path = globalHistory.location.pathname

  return (
    <StyledMenuContainer {...props}>
      {title && <h3>{title}</h3>}
      <StyledList
        isVertical={props.isVertical}
        theme={props.theme}
        alignCenter={props.alignCenter}
        stretched={props.stretched}
      >
        {items.map(({ label, href, icon }) => {
          return (
            <li key={label}>
              <LinkButton
                ignoreCase={props.ignoreCase}
                theme={props.theme}
                href={href}
                icon={icon}
                stretched={props.stretched}
                isActive={path === href}
              >
                {label}
              </LinkButton>
            </li>
          )
        })}
      </StyledList>
    </StyledMenuContainer>
  )
}

const StyledMenuContainer = styled.nav`
  display: flex;
  margin: 1em 0;
  flex-direction: ${props => (props.isVertical ? "column" : "row")};
  h3 {
    padding-left: 12px;
    color: ${Colors.RED};
  }
`

const StyledList = styled.ul`
  display: flex;
  flex-direction: ${props => (props.isVertical ? "column" : "row")};
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    margin-top: ${props => (props.theme === "list" ? "5px" : "")};
    align-items: center;
    justify-content: ${props => (props.alignCenter ? "center" : "flex-start")};
  }
  a {
    ${props => (props.stretched ? "flex:1;" : "")};
    color: ${props => (props.isVertical ? Colors.GREY_11 : "")};
    padding: ${props => (props.theme === "list" ? "" : "5px 10px")};
  }
  ${media.md`
    span::before {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    } 
  `}
`

export default Menu
