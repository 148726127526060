import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"

import Logo from "../images/Logo.svg"

import {
  credit,
  footerItems,
  footerText,
  links,
  navBarItems,
} from "../utils/data"

const defaultMainRenderer = children => (
  <LayoutMainContainer>{children}</LayoutMainContainer>
)

const Layout = ({ children, mainRenderer }) => {
  const finalMainRenderer = useMemo(() => mainRenderer || defaultMainRenderer, [
    mainRenderer,
  ])

  return (
    <>
      <Navbar
        navBarItems={navBarItems}
        link={{ label: "GET IN TOUCH", href: "/contact" }}
        logo={Logo}
      />
      {finalMainRenderer(<main>{children}</main>)}
      <Footer
        footerItems={footerItems}
        logo={Logo}
        footerText={footerText}
        links={links}
        credit={credit}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export const LayoutMainContainer = styled.div`
  margin-top: 58px;
  ${media.md`
    margin-top: 65px;
  `}
`

export default Layout
