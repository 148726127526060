import { Link } from "gatsby"
import React from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import { Colors } from "../../utils/constants"
import Menu from "../Menu/Menu"

const Footer = ({
  footerItems,
  logo,
  footerText,
  links,
  credit,
  noVerticalSpacing,
}) => {
  return (
    <StyledFooter noVerticalSpacing={noVerticalSpacing}>
      <div>
        <div>
          {logo && (
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          )}
          {footerText && <p>{footerText}</p>}
          {links && <Menu items={links} />}
        </div>
        <StyledMenu footerItemsLength={footerItems.length}>
          {footerItems &&
            footerItems.map((list, index) => (
              <Menu
                key={index}
                items={list.items}
                isVertical
                title={list.title}
              />
            ))}
        </StyledMenu>
      </div>
      {credit && <p> {credit}</p>}
    </StyledFooter>
  )
}

const StyledMenu = styled.div`
  display: flex;
  justify-content: ${props =>
    props.footerItemsLength > 1 ? "space-between" : "flex-start"};
  ${media.md`
    justify-content: ${props =>
      props.footerItemsLength > 1 ? "space-between" : "center"} ;
  `}
`
const StyledFooter = styled.footer`
  padding: 20px 40px;
  ul {
    padding-left: 0;
  }
  & > div > div:first-of-type {
    margin-top: 34px;
    a {
      color: ${Colors.RED_2};
    }
    li:not(:last-of-type):after {
      content: "•";
      color: ${Colors.RED_2};
      font-weight: bold;
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: ${props => (props.noVerticalSpacing ? "0" : "34px")};
    border-bottom: 1px solid ${Colors.GREY};
    border-top: 1px solid ${Colors.GREY};
  }
  p {
    color: ${Colors.GREY_11};
    line-height: 2;
  }
  a {
    font-size: 12px;
  }
  ${media.md`
    padding: ${props => (props.noVerticalSpacing ? "0 100px" : "70px 100px")};
    margin:auto;
    max-width:1440px;
    & > div {
      flex-direction: row;
      justify-content: space-between;
      border: none;
    }
    & > div > div:first-of-type{
      flex : 2;
    }
    & > div > div:last-of-type {
      flex: 1;
    }
  `}
`

export default Footer
