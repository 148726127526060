export const navBarItems = [
  { label: "Home", href: "/" },
  { label: "What we do", href: "/technology" },
  { label: "Who we are", href: "/about" },
  { label: "Join us", href: "/careers" },
]
export const footerItems = [
  {
    title: "Navigation",
    items: [
      { label: "Home", href: "/" },
      { label: "What we do", href: "/technology" },
      { label: "Who we are", href: "/about" },
      { label: "Join us", href: "/careers" },
      { label: "Contact", href: "/contact" },
    ],
  },
]

export const credit = "xTECH © All rights reserved - 2020."
export const footerText =
  "We translate your ideas into the state of the art digital products. We develop and build fast, reliable experiences that stand the test of time."
export const links = [
  { label: "TERMS", href: "/terms" },
  { label: "PRIVACY", href: "/privacy" },
  { label: "FAQ", href: "/faq" },
]
