import { Link } from "gatsby"
import React, { useState } from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import { Colors } from "../../utils/constants"

const theme = {
  DEFAULT: {
    color: Colors.BLACK,
    fontWeight: "regular",
    borderColor: Colors.WHITE,
  },
  RED: {
    color: Colors.WHITE,
    backgroundColor: Colors.RED,
    fontWeight: "bold",
    borderColor: Colors.RED,

    colorOnHover: Colors.RED,
    backgroundColorOnHover: Colors.WHITE,
    borderOnHover: Colors.RED,
  },
  INVERTED: {
    color: Colors.RED,
    backgroundColor: Colors.WHITE,
    fontWeight: "bold",
    borderColor: Colors.RED,

    colorOnHover: Colors.WHITE,
    backgroundColorOnHover: Colors.RED,
    borderOnHover: Colors.RED,
  },
  WHITE_INVERTED: {
    color: Colors.RED,
    backgroundColor: Colors.WHITE,
    fontWeight: "bold",
    borderColor: Colors.WHITE,

    colorOnHover: Colors.WHITE,
    backgroundColorOnHover: Colors.RED,
    borderOnHover: Colors.WHITE,
  },
  PINK: {
    color: Colors.RED,
    backgroundColor: Colors.PINK,
    fontWeight: "bold",
    borderColor: Colors.PINK,

    colorOnHover: Colors.WHITE,
    backgroundColorOnHover: Colors.RED,
    borderOnHover: Colors.RED,
  },
  LIST: {
    color: Colors.GREY_7,
    backgroundColor: Colors.WHITE,
    fontWeight: "bold",
    borderColor: Colors.LIGHT_GREY,

    colorOnHover: Colors.WHITE,
    backgroundColorOnHover: Colors.RED,
    borderOnHover: Colors.RED,
    borderRadiusOnHover: "15px",
  },
}

const LinkButton = ({
  children,
  icon,
  hoverIcon,
  theme = "default",
  href,
  uppercase = false,
  isActive = false,
  stretched = false,
  ignoreCase = true,
  ...props
}) => {
  const [linkIcon, setLinkIcon] = useState(icon)
  const onLinkHover = () => hoverIcon && setLinkIcon(hoverIcon)
  const onLinkHoverReset = () => hoverIcon && setLinkIcon(icon)
  //TODO: check if mailto link work with this or not
  const isInternalLink = /^\/(?!\/)/.test(href)

  return isInternalLink ? (
    <StyledInternalButton
      theme={theme.toUpperCase()}
      to={href}
      onMouseOver={onLinkHover}
      onFocus={onLinkHover}
      onMouseLeave={onLinkHoverReset}
      //TODO: take a look at this it doesn't make sense to me using number instead of booleans
      //TODO: how to pass props correctly with UpperCase name
      uppercase={uppercase ? 1 : 0}
      isactive={isActive ? 1 : 0}
      stretched={stretched ? 1 : 0}
      ignorecase={ignoreCase ? 1 : 0}
      title={children}
      {...props}
    >
      {children && <span title={children}>{children}</span>}

      {linkIcon && <img src={linkIcon} alt="Button icon" />}
    </StyledInternalButton>
  ) : (
    <StyledExternalButton
      aria-label={children || "button"}
      theme={theme.toUpperCase()}
      href={href}
      onMouseOver={onLinkHover}
      onFocus={onLinkHover}
      onMouseLeave={onLinkHoverReset}
      title={children}
      uppercase={uppercase}
      isactive={isActive}
      stretched={stretched}
      ignorecase={ignoreCase ? 1 : 0}
      {...props}
    >
      {children && <span title={children}>{children}</span>}

      {linkIcon && <img src={linkIcon} alt="Button icon" />}
    </StyledExternalButton>
  )
}

//TODO there's redundant style use css utility to minimize it
const StyledExternalButton = styled.a`
  border-radius: 21px;
  display: ${props => (props.stretched ? "flex" : "inline-flex")};
  justify-content: space-between;
  text-decoration: none;
  padding: 12px 10%;
  align-items: center;
  font-size: 10px;
  text-transform: ${props => (props.uppercase ? "uppercase" : null)};
  border: solid ${props => theme[props.theme].borderColor};
  border-width: ${props => (props.theme === "LIST" ? "1px 0px" : "2px")};
  color: ${props => theme[props.theme].color};
  background-color: ${props => theme[props.theme].backgroundColor};
  font-weight: ${props =>
    props.isactive ? "bold" : theme[props.theme].fontWeight};
  & *:only-child {
    z-index: 1;
    margin: 0 auto;
  }
  &:hover {
    color: ${props => theme[props.theme].colorOnHover};
    background-color: ${props => theme[props.theme].backgroundColorOnHover};
    border-color: ${props => theme[props.theme].borderOnHover};
    ${props =>
      props.imagehovereffect
        ? `img {
          filter: brightness(1) invert(0);
        }`
        : null}
  }

  ${media.md`
    padding: 12px 37px;
    & {
      font-size: 14px;
    }
    &:hover {
      font-weight: bold;
    }
  `}
`

const StyledInternalButton = styled(Link)`
  text-transform: ${props =>
    props.ignorecase ? "none" : props.uppercase ? "uppercase" : "lowercase"};
  & span:first-letter {
    text-transform: uppercase;
  }

  border-radius: 21px;
  display: ${props => (props.stretched ? "flex" : "inline-flex")};
  justify-content: space-between;
  text-decoration: none;
  padding: 12px 10%;
  align-items: center;
  font-size: 10px;
  border: solid ${props => theme[props.theme].borderColor};
  border-width: ${props => (props.theme === "LIST" ? "1px" : "2px")};
  color: ${props => theme[props.theme].color};
  background-color: ${props => theme[props.theme].backgroundColor};
  font-weight: ${props =>
    props.isactive ? "bold" : theme[props.theme].fontWeight};

  & *:only-child {
    margin: 0 auto;
  }
  ${props =>
    props.reverseFilter
      ? `img {
        filter: brightness(0) invert(1);
      }`
      : null}
  &:hover {
    color: ${props => theme[props.theme].colorOnHover};
    background-color: ${props => theme[props.theme].backgroundColorOnHover};
    border-color: ${props => theme[props.theme].borderOnHover};

    img {
      filter: ${props =>
        !props.imagehovereffect || props.reverseFilter
          ? "none"
          : "brightness(0) invert(1)"};
    }
  }

  ${media.md`
  padding: 12px 37px;
    & span{
      font-size: 14px;
    }
    &:hover {
      font-weight: bold;
    }
  `}
`

export default LinkButton
