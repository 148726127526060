export const Colors = {
  BLACK: "#000000",
  GREY: "#2b292d99",
  GREY_1: "#333333",
  GREY_2: "#3e3e3e",
  GREY_3: "#e6e6e61a",
  GREY_4: "#454545",
  GREY_5: "#4a4a4a",
  GREY_6: "#8e8989",
  GREY_7: "#9A9A9A",
  GREY_8: "#c2c2c2",
  GREY_9: "#e5e5e5",
  GREY_10: "#f7f7f7",
  GREY_11: "#6e6e6e",
  GREY_12: "#585858",
  LIGHT_GREY: "#fafafa",
  WHITE: "#ffffff",
  RED: "#e32a1e",
  RED_1: "#de281b",
  RED_2: "#cc261b",
  RED_3: "#E62B1E",
  PINK: "#fde5e3",
}
