import { Link } from "gatsby"
import React from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import { Colors } from "../../utils/constants"
import LinkButton from "../LinkButton/LinkButton"
import Menu from "../Menu/Menu"

const Navbar = ({ navBarItems, link, logo }) => {
  return (
    <header>
      <StyledNavbarContainer>
        {logo && (
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        )}
        <SyledMenuBtn type="checkbox" id="menu-btn" />
        <StyledIcon htmlFor="menu-btn">
          <span />
        </StyledIcon>
        {navBarItems && <StyledMenu items={navBarItems} alignCenter />}
        {link && (
          <LinkButton theme="red" href={link.href}>
            {link.label}
          </LinkButton>
        )}
      </StyledNavbarContainer>
    </header>
  )
}

const StyledNavbarContainer = styled.nav`
  background-color: ${Colors.WHITE};
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  img {
    float: left;
    padding: 20px 20px;
  }
  & > a:last-of-type {
    display: none;
  }
  ${media.md`
    display: flex;
    justify-content: space-around;
    align-items: center;
    & > a:last-of-type {
      display: inline-flex;
      align-self: center;
    }
    & label {
      display: none;
    }
    img {
      padding: 25px 20px;
    }
  `}
`
const SyledMenuBtn = styled.input`
  display: none;
  &:checked ~ nav,
  &:checked ~ nav ul,
  &:checked ~ a {
    display: block;
    text-align: center;
    max-height: 360px;
    margin: 15px;
    ${media.md`
      display:flex;
      margin: 0;
    `}
  }
  &:checked ~ label span {
    background: transparent;
  }
  &:checked ~ label span:before {
    transform: rotate(-45deg);
    width:100%;
  }
  &:checked ~ label span:after {
    transform: rotate(45deg);
    width:100%;
  }
  &:checked ~ label:not(.steps) span:before,
  &:checked ~ label:not(.steps) span:after {
    top: 0;
  }
`

const StyledIcon = styled.label`
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
  transform:scaleX(-1);
  & span {
    background: ${Colors.BLACK};
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 14px;
    border-radius: 5px;
  }
  & span:before,
  & span:after {
    background: ${Colors.BLACK};
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    border-radius: 5px;
  }
  & span:before {
    top: 5px;
    width: 160%;
  }
  & span:after {
    top: -5px;
    width: 140%;
  }
`

const StyledMenu = styled(Menu)`
  display: none;
  margin: 0;
  & ul {
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: ${Colors.WHITE};
    clear: both;
    transition: max-height 0.2s ease-out;
    & li a {
      display: block;
      padding: 20px 20px;
      text-decoration: none;
    }
  }
  ${media.md`
    display:flex;
  `}
`

export default Navbar
